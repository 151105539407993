<template>
	<!-- 首页 -->
	<div id="home-container">
		<header-navbar
			class="fixed"
			bgTheme="bg-transparent-font-white"
		></header-navbar>

		<!-- 顶部表单 -->
		<div class="top-form">
			<!-- 表单 -->
			<div class="form-box">
				<!-- <div class="top">
					<div class="item">
						<form-input label="称呼姓名"></form-input>
					</div>
					<div class="item">
						<form-input label="联系电话"></form-input>
					</div>
					<div class="item">
						<form-input label=""></form-input>
					</div>
				</div> -->
			</div>

			<img :src="pageImage['bg-1']" alt="" />
		</div>

		<div class="image-box">
			<img class="bg-2" :src="pageImage['bg-2']" alt="" />
			<div class="click-box">
				<grid-box-item
					:path="item"
					v-for="item in routes"
					:key="item"
				></grid-box-item>
			</div>
		</div>

		<img class="bg-3" :src="pageImage['bg-3']" alt="" />

		<!-- 服务咨询 -->
		<div class="consultation-box" v-if="false">
			<div class="title">服务资讯</div>

			<div class="content-box">
				<div class="left">
					<div class="contact-box">
						<p class="sub-title">邮箱</p>
						<p>bak@cmmiv.COM</p>
						<p class="sub-title">服务电话</p>
						<p>0755-32904615</p>
						<p class="sub-title">地址</p>
						<p>深圳市宝安区西乡街道固戍社区下围园经发智造园A栋A区501</p>
					</div>
				</div>
				<div class="right">
					<div class="form-box">
						<div class="top">
							<div class="item">
								<form-input label="项目名称"></form-input>
							</div>
							<div class="item">
								<form-input label="称呼姓名"></form-input>
							</div>
							<div class="item">
								<form-input label="联系电话"></form-input>
							</div>
						</div>

						<div class="item">
							<form-input
								label="描述您的需求如APP开发、小程序开发等"
								inputType="textarea"
							></form-input>
						</div>
					</div>

					<div class="btn-box">
						<div class="btn" v-loading="isSubmit" @click="submit">提交</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 底部 -->
		<floor-bg class="floor-bg" bgTheme="floor-bg-white"></floor-bg>
	</div>
</template>

<script setup>
import { computed, ref, reactive } from "vue";
import { ElNotification } from "element-plus";
const pageImage = computed(() => {
	const imgs = ["bg-1", "bg-2", "bg-3"];

	const result = {};

	for (let i = 0; i < imgs.length; i++) {
		const item = imgs[i];
		result[item] = require(`@/assets/images/page-body/home/${item}.png`);
	}

	return result;
});

// 是否已提交
const isSubmit = ref(false);

// 提交
const submit = () => {
	if (isSubmit.value) {
		return;
	}
	isSubmit.value = true;
	setTimeout(() => {
		ElNotification({
			title: "消息",
			message: "提交成功",
			type: "success",
		});
		isSubmit.value = false;
	}, 2000);
};

// 当前页面 跳转路由
const routes = [
	"/systemFinancialInstitution",
	"/systemArbitrationCourt",
	"/systemNantianJustice",
	"/systemShenzhenAccess",
	"/systemSteelStructure",
	"/systemExpressLogistics"
];
</script>

<style lang="scss" scoped>
#home-container {
	width: 100%;
	display: flex;
	align-content: center;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;

	// 顶部表单
	.top-form {
		position: relative;
		margin-bottom: 50px;

		.form-box {
			position: absolute;
		}
	}

	.image-box {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;

		.click-box {
			position: absolute;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 490px);
			width: 1250px;
			top: 1620px;
			cursor: pointer;
		}
	}

	.bg-2 {
		margin-bottom: 100px;
	}
	.bg-3 {
		cursor: pointer;
		margin-bottom: 100px;
	}

	.consultation-box {
		width: 1460px;
		height: 600px;

		.title {
			font-size: 32px;
		}

		.content-box {
			margin: 50px 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			.left {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;

				.contact-box {
					width: 425px;
					font-size: 20px;
					padding: 20px 60px;
					border-radius: 5px;
					border: 1px solid #dddddd;
					box-sizing: border-box;
					text-align: center;

					p {
						line-height: 30px;
						margin-bottom: 20px;
					}
					.sub-title {
						font-size: 25px;
						line-height: 60px;
					}
				}
			}
			.right {
				width: calc(100% - 425px);
				.form-box {
					padding: 25px;

					.top {
						display: flex;
						margin-bottom: 90px;

						.item {
							margin-right: 30px;

							&:nth-last-child(1) {
								margin: 0;
							}
						}
					}
				}

				.btn-box {
					width: 100%;
					text-align: center;
					margin-top: 45px;
					.btn {
						width: 250px;
						height: 58px;
						background: #005de8;
						box-shadow: 0px 0px 6px rgba(0, 93, 232, 0.5);
						opacity: 1;
						border-radius: 10px;
						line-height: 58px;
						color: white;
						margin: auto;
						cursor: pointer;
						transition: opacity 1s;

						&.el-loading-parent--relative {
							box-shadow: none;
						}

						&:active {
							opacity: 0.6;
						}
					}
				}
			}
		}
	}

	.floor-bg {
		margin-top: 125px;
	}
}
</style>
